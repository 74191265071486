
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET} from '@angular/router';

import { Subscription, of } from 'rxjs';
import { HttpService } from './http.service';
import { GrowlService } from './growl.service';




interface IBreadcrumb {
  label: string;
  params: Params;
  parentLabel: string;
  parentUrl: string;
  url: string;
  queryParams: any;
}

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  usersFavorites = [];

  route: string;

  public breadcrumbs: IBreadcrumb[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    // subscribe to the NavigationEnd event

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  getBreadcrumbs(route: ActivatedRoute, url: string="", breadcrumbs: IBreadcrumb[]=[], parentLabel?, parentUrl?): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "title";


    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route

      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      // if (child.snapshot.data.title === '') {
      //   continue;
      // }

      // if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
      if (!child.snapshot.data[ROUTE_DATA_BREADCRUMB]) {
        return this.getBreadcrumbs(child, url, breadcrumbs, parentLabel, parentUrl);
      }

      if (route.snapshot.url.length === 0) {
        if (route.firstChild.snapshot.url.length !== 0) {
          url += '/' + route.firstChild.snapshot.url.map(segment => segment.path).join('/');
        }
      } else {
        url += '/' + route.snapshot.url.map(segment => segment.path).join('/');
      }

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        parentLabel: parentLabel || '',
        parentUrl: parentUrl || '/',
        queryParams: child.snapshot.queryParams,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      let _parentLabel = breadcrumb.label;
      let _parentUrl = breadcrumb.url;

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs, _parentLabel, _parentUrl);
    }
  }

  getUsersFavorites() {
    return this.httpService.request({ method: 'get', url: 'assets/data/temp/favorites-list.json', isCustomUrl: true });
  }

  addToFavorites(arr, e) {
    this.usersFavorites.push(arr);

    let bodyObj = [];
    arr.map((item) => {
      bodyObj.push({
        label: item.label,
        params: item.params,
        queryParams: item.queryParams,
        parentLabel: item.parentLabel,
        parentUrl: item.parentUrl,
        url: item.url
      })
    });

    e.target.classList.add('btn-loading');
    this.httpService.request({ isCustomUrl: true, method: 'get', url: 'assets/data/temp/add-to-favorites.json', bodyObject: bodyObj}).subscribe(
      res => {
        if (res.isOk) {
          this.growlService.growl({ title: 'Başarılı', type: 'notice', message: 'Sayfa Favorilere Eklendi' });
        } else {
          this.growlService.handleError(res);
        }
        e.target.classList.remove('btn-loading');
      },
      err => {
        this.growlService.handleError(err);
        e.target.classList.remove('btn-loading');
      }
    );
  }

  removeFromFavorites(id, e) {
    return this.httpService.request({ method: 'get', url: 'assets/data/temp/remove-from-favorites.json', bodyObject: {id: id}, loadingEffectElement: e.target });
  }

}
