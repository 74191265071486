import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-batch-devices-modal',
  templateUrl: './batch-devices-modal.component.html',
  styleUrls: []
})
export class BatchDevicesModalComponent implements OnInit {

  title: string;
  filter: {};
  resource: string;
  form: FormGroup;
  selectedAll: any;

  protected commonDataService: CommonDataService;

  items = [];
  fields = [
      { header: 'Project', name: 'projectName' },
      { header: 'Vessel', name: 'vesselName' },
      { header: 'System', name: 'systemName' },
      { header: 'Device Tag', name: 'tag' },
      { header: 'Name', name: 'name' },
      { header: 'Status', name: 'status' },
      { header: 'Checklist', name: 'checkListGroupName' }
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);
    this.form = new FormGroup({
      checklistId: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.getInitialList();
  }

  getInitialList(){
    var body = {
      Pagination: null,
      Filter: this.filter,
      Sorter: null
    };

    this.httpService
      .request({
        method: "post",
        bodyObject: body,
        url: this.resource + "/search"
      })
      .subscribe(
        res => {
          if (res.isOk) {
            this.items = res.handled.data;
            if (this.items.length < 1) {
              this.growlService.growlMessage("Response","error","No Devices Found!!");
              this.bsModalRef.hide();
            } else {
              this.items.forEach(item => {
                item.selected = false;
              });
            }
          } else {
            this.growlService.handleError(res);
          }
        },
        err => {
          this.growlService.handleError(err);
        }
      );
  }

  selectAll() {
    for (var i = 0; i < this.items.length; i++) {
      this.items[i].selected = this.selectedAll;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.items.every(function(item:any) {
        return item.selected == true;
      })
  }

  onSubmit(e) {

    var body = this.form.value;
    body.deviceIds = [];
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].selected == true) {
        body.deviceIds.push(this.items[i].id);
      }
    }
    console.log(body);

    this.httpService
      .request({
        method:"post",
        url: this.resource + "/batch/checklist-update",
        bodyObject: body,
        loadingEffectElement: e.target.closest("form")
      })
      .subscribe(
        res => {
          if (res.isOk) {
            let successMsg = "Selected Device Checklists are updated";
            this.getInitialList();
            this.growlService.growl({
              title: "Success",
              type: "notice",
              message: successMsg
            });
          } else {
            this.growlService.handleError(res);
          }
        },
        err => {
          this.growlService.handleError(err);
        }
      );

    //this.triggerEvent();
  }

  triggerEvent() {
    this.bsModalRef.hide();
  }

}
