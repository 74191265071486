import { Component, Input } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup
} from "@angular/forms";

@Component({
  selector: "app-card-table-page",
  templateUrl: "./app-card-table-page.component.html"
})
export class AppCardTablePageComponent {
  @Input() cardHeaderName: string = "";
}
