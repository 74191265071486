import { Injectable } from '@angular/core';

// import { SlimLoadingBarService } from 'ng2-slim-loading-bar';

import * as myGlobals from '../globals';


@Injectable()
export class LoadingService {

  requestCount = 0;
  timeOut: any;

  constructor(
    // private slimLoadingBarService: SlimLoadingBarService,
  ) { }

  start() {
    // this.slimLoadingBarService.start(() => {
    // });
    this.requestCount++;
  }

  stop(){
    // this.slimLoadingBarService.stop();
  }

  requestCompleted() {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.timeOut = setTimeout(() => {
        this.complete();
      }, myGlobals.loadingBarBufferTime);
    } else {
      window.clearTimeout(this.timeOut);
    }
  }

  complete() {
    // this.slimLoadingBarService.complete();
  }

}
