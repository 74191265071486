import { Component, Input, EventEmitter, Output } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup
} from "@angular/forms";

@Component({
  selector: "app-pagination",
  templateUrl: "./app-pagination.component.html"
})
export class AppPaginationComponent {

  @Input() currentPage = 1;
  @Input() itemsPerPage = 1;
  @Input() totalItems = 0;

  @Output() onExternalPageChange: EventEmitter<any> = new EventEmitter();

  pageChanged(event: any): void {
    this.onExternalPageChange.emit(new Pagination(event.page-1, this.itemsPerPage));
  }

  pageSizeChanged(event: any): void {
    this.onExternalPageChange.emit(new Pagination(this.currentPage-1, this.itemsPerPage));
  }

}

export class Pagination {
  _pageIndex;
  _pageSize;

  constructor(pageIndex, pageSize) {
    this._pageIndex = pageIndex;
    this._pageSize = pageSize;
  }
}
