import {
  Component,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { RoleGuard } from "../../../guards/role.guard";
import { HttpService } from "../../../services/http.service";
import { GrowlService } from "../../../services/growl.service";
import * as sharedMethods from "../../../shared-methods";

@Component({
  selector: "app-card-table",
  templateUrl: "./app-card-table.component.html"
})
export class AppCardTableComponent {
  protected httpService: HttpService;
  protected growlService: GrowlService;
  @Input() items = [];
  @Input() fields = [];
  @Input() filters = [];
  @Input() addButtonTxt = "New";
  @Input() downloadRouterLink = "";
  @Input() permissionRouterLink = "";
  @Input() previewRouterLink = "";
  @Input() editRouterLink = "";
  @Input() createRouterLink = "";
  @Input() templateRouterLink = "";
  @Input() permissionBase = "";
  @Input() customLinks: any[] = [];
  @Input() buttonVisibilities: any;
  @Input() isHidden: Boolean = true;
  @Input() hasTemplate: Boolean = false;
  @Input() openModalButtonTxt = "";
  @Input() batchUpdate:Boolean = false;

  approvedCustomLinks: any[] = [];
  approvedActionButtons: any[] = [];
  @Input() isAllHidden: Boolean = false;
  @Input() isActionsHidden: Boolean = false;
  @Input() isAudit: Boolean = false;

  filterVisible = false;
  isInit=true;

  @Output() onRemoveItem: EventEmitter<any> = new EventEmitter();
  @Output() onAudit: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() callbackFunction: EventEmitter<any> = new EventEmitter();
  @Output() openModalFunction: EventEmitter<any> = new EventEmitter();
  @Output() openBatchModalFunction: EventEmitter<any> = new EventEmitter();

  @ViewChild("inputSearch") private elementRef: ElementRef;

  protected filterFormGroup: FormGroup = new FormGroup({});

  public ngAfterViewInit(): void {
    if (this.elementRef)
      this.elementRef.nativeElement.focus();
    this.approvedCustomLinks = [];
    this.approvedActionButtons = [];

    this.customLinks.forEach(item => {
      if (this.permissionOfCustom(item.action)) {
        if (item.callbackFunctionParam) {
          this.approvedActionButtons.push(item);
        } else if (item.routerLink) {
          this.approvedCustomLinks.push(item);
        }
      }
    });

    if (this.filters) {
      this.filters.forEach(filterRow => {
        filterRow.forEach(filter => {
          this.filterFormGroup.addControl(filter.name, new FormControl(""));
        });
      });
    }
  }

  constructor(private _renderer: Renderer2, private roleGuard: RoleGuard) {}

  onCallbackFunction(item, parameter) {
    if (parameter != undefined) {
      parameter.id = item.id;
    }

    let param = parameter;

    this.callbackFunction.emit(parameter);
  }

  onOpenModalWithComponent() {
    this.openModalFunction.emit(null);
  }

  onAuditClick(id) {
    this.onAudit.emit(id);
  }

  removeItem(id) {
    this.onRemoveItem.emit(id);
  }

  permissionOfCreate() {
    return this.permissionBase + "_create";
  }

  permissionOfUpdate() {
    return this.permissionBase + "_update";
  }

  permissionOfDelete() {
    return this.permissionBase + "_delete";
  }

  permissionOfGet() {
    return this.permissionBase + "_get";
  }

  permissionOfCustom(action) {
    if (!action) return true;
    return this.roleGuard.canDisplay(this.permissionBase + "_" + action);
  }

  onSearchText(event: KeyboardEvent) {
    if (event.key == "Enter") {
      var filter = { QuerySearch: (<HTMLInputElement>event.target).value };
      this.onSearch.emit(filter);
    }
  }

  onSearchClick(event) {
    var filter = this.prepareFilter();
    this.onSearch.emit(filter);
  }

  prepareFilter(){
    var filter = {};
    if (this.filterVisible) {
      filter = this.filterFormGroup.value;
    } else {
      var text = this.elementRef.nativeElement.value;
      filter = { QuerySearch: text };
    }
    return filter;
  }

  toggleFilter(): void {
    if (this.filterVisible) {
      this.filterVisible = false;
    } else {
      this.filterVisible = true;
    }
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  printTable(): void {
    sharedMethods.onPrint("card-list-table");
  }

  onOpenBatchModalClick(event) {
    var filter = this.prepareFilter();
    this.openBatchModalFunction.emit(filter);
  }
}
