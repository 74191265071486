import { Directive, Input, SimpleChanges } from "@angular/core";
import { Host, Self, Optional } from "@angular/core";
import { Observable, Subject, of, concat } from "rxjs";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  catchError,
  map
} from "rxjs/operators";
import { NgSelectComponent } from "@ng-select/ng-select";
import { CommonDataService } from "../services/common-data.service";
@Directive({
  selector: "[appAutoCompleteMenuDirective]"
})
export class AutoCompleteMenuDirective {
  // autocomplete element variables
  data$: Observable<any>;
  loading = false;
  input$ = new Subject<string>();

  constructor(
    @Host() @Self() @Optional() public hostSelectComponent: NgSelectComponent,
    private commonDataService: CommonDataService
  ) {}

  ngOnInit() {
    this.loadData();
    this.data$.subscribe(res => {
      this.hostSelectComponent.items = [...res];
      this.hostSelectComponent.bindValue = "id";
      this.hostSelectComponent.bindLabel = "name";
      this.hostSelectComponent.ngOnChanges({
        items: {
          previousValue: [],
          currentValue: [...this.hostSelectComponent.items],
          firstChange: false,
          isFirstChange: () => false
        }
      });
      this.hostSelectComponent.detectChanges();
    });
    this.hostSelectComponent.typeahead = this.input$;
    this.hostSelectComponent.detectChanges();
  }

  loadData() {
    let self = this;
    this.data$ = concat(
      of([]), // default items
      this.input$.pipe(
        debounceTime(200),
        distinctUntilChanged(), // Only emit when the current value is different than the last.
        tap(() => {
          this.hostSelectComponent.loading = true;
          self.hostSelectComponent.detectChanges();
        }),
        switchMap(term =>
          this.commonDataService.getAutoCompleteMenuData(term).pipe(
            catchError(() => of([])),
            map(res => {
              return res;
            }),
            tap(() => {
              this.hostSelectComponent.loading = false;
              self.hostSelectComponent.detectChanges();
            })
          )
        )
      )
    );
  }
}
