import { BsModalRef } from "ngx-bootstrap/modal";
import { BsModalService } from "ngx-bootstrap/modal";
import { Component, Input } from "@angular/core";
import { navItems } from "../../_nav";
import { AppInjector } from "../../services/app-injector.service";
import { AuthService } from "../../services/auth.service";
import { FavoritesService } from "../../services/favorites.service";
import { CommonDataService } from "../../services/common-data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html"
})
export class DefaultLayoutComponent {
  bsModalRef: BsModalRef;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public result : any;
  constructor(
    private authService: AuthService,
    private favoritesService: FavoritesService,
    private modalService: BsModalService,
    private commonDataService: CommonDataService,
    private router: Router
  ) {
    const injector = AppInjector.getInjector();
    this.modalService = injector.get(BsModalService);
    this.changes = new MutationObserver(mutations => {
      this.sidebarMinimized = document.body.classList.contains(
        "sidebar-minimized"
      );
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    if (!this.router.url.includes("login") && !this.router.url.includes("reset-password")) {
      this.loadMenu();
      this.showUsername();
    }

  }
  currentPerson: any;

  showUsername() {
    var user: any = JSON.parse(localStorage.getItem("userInfo"));
    if (user != null) {
      (user.userName) ? this.currentPerson = " " + user.userName : "null";
    }
  }

  loadMenu() {
    const user = this.authService.currentUser();
    if (!user || !this.authService.isAuthenticated()) {
      return;
    }
    if (user.permissions == null) {
      user.permissions = [];
    }


    this.commonDataService.getMenu().subscribe(
      res => {

        this.result = res;
        this.navItems = [];

        var mainItems = this.result.filter(f => f.parentId == null && f.icon != null);
        mainItems.sort((a, b) =>
          a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0
        );
        mainItems.forEach(mainItem => {
          var middleItems = this.result.filter(f => f.parentId == mainItem.id);

          var childrenOfRootItem = [];
          middleItems.forEach(middleItem => {
            var childItems = this.result.filter(f => f.parentId == middleItem.id);
            if (childItems.length > 0) {
              var allowedItems = childItems.filter(
                f =>
                  f.permissionCode == null ||
                  user.permissions.findIndex(p => p.code == f.permissionCode) >
                  0
              );
              if (allowedItems.length > 0) {
                middleItem.children = allowedItems;
                childrenOfRootItem.push(middleItem);
              }
            } else {
              if (
                middleItem.permissionCode == null ||
                user.permissions.findIndex(
                  p => p.code == middleItem.permissionCode
                ) > 0
              ) {
                childrenOfRootItem.push(middleItem);
              }
            }
          });

          if (childrenOfRootItem.length > 0) {
            mainItem.children = childrenOfRootItem;
          }
          if (
            (mainItem.children && mainItem.children.length > 0) ||
            mainItem.parentId == null
          ) {
            this.navItems.push(mainItem);
          }
        });
      },
      err => {
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  openPage(val) {
    if (val && val.url) {
      this.router.navigate([val.url]);
    }
  }
}
