import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-group-line',
  templateUrl: './app-form-group-line.component.html',
  styleUrls: ["./app-form-group-line.component.scss"]
})
export class AppFormGroupLineComponent implements OnInit {
  @Input() label: string = '';
  @Input() lineColor: string = '0AB4F0';
  @Input() backgroundColor: string = 'FFFFFF';
  @Input() borderSize: string = '1';

  ngOnInit(): void {
    document.documentElement.style.setProperty('--line-color', '#' + this.lineColor);
    document.documentElement.style.setProperty('--background-color', '#' + this.backgroundColor);
    document.documentElement.style.setProperty('--border-size', this.borderSize + 'px');
  }

}
