import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-role-select-modal',
  templateUrl: './role-select-modal.component.html',
  styleUrls: []
})
export class RoleSelectModalComponent implements OnInit {

  title: string;
  item: any;
  index: number;
  pageData: any;
  form: FormGroup;
  public submitEvent: EventEmitter<any> = new EventEmitter();
  isFormChecked = false;
  protected commonDataService: CommonDataService;

  items = [];

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);

    this.form = new FormGroup({
      roleId: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.item != null) {
      this.patchFormValues();
    }
  }

  patchFormValues() {
    this.form.patchValue({
      roleId: this.item.role.id,
    });
  }

  onSubmit(e) {
    this.isFormChecked = true;
    if (!this.form.valid) {
      sharedMethods.shakeSubmitButton(e.target);
      return;
    }

    this.triggerEvent();

  }

  getRole(itemId) {
    if (itemId != 0)
      this.httpService
        .request({
          method: "get",
          url: "roles/" + itemId
        })
        .subscribe(
          res => {
            if (res.isOk) {
              this.item = res.handled.data;
            } else {
              this.growlService.handleError(res);
            }
          },
          err => {
            this.growlService.handleError(err);
          }
        );
  }

  triggerEvent() {
    this.item.index = this.index;
    this.submitEvent.emit(this.item);
    this.bsModalRef.hide();
  }

}
