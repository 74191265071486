import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-import-devices-modal',
  templateUrl: './import-devices-modal.component.html',
  styleUrls: []
})
export class ImportDevicesModalComponent implements OnInit {

  title: string;
  item: any[] = [];
  pageData: any;
  index: number;
  form: FormGroup;
  public submitEvent: EventEmitter<any> = new EventEmitter();
  isFormChecked = false;
  protected commonDataService: CommonDataService;
  uploadUrl = "devices/upload";


  importedFileId=0;
  items = [];
  fields = [
      { header: "Project", name: "projectName" },
      { header: "Vessel", name: "vesselName" },
      { header: "System", name: "systemName" },
      { header: "Device", name: "deviceName" },
      { header: "Tag", name: "tag" }
    ];

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);

    this.form = new FormGroup({

    });
  }

  ngOnInit() {
    this.pageData = this.item;
    if (this.index > -1) {
      this.patchFormValues();
    }
  }

  public uploadFinished = (event) => {
    this.importedFileId = event.data.importedFileId;
    this.items = event.data.devices;
  }

  patchFormValues() {
    this.form.patchValue({

    });
  }

  onSubmit(e) {
    this.isFormChecked = true;

    if (!this.form.valid) {
      sharedMethods.shakeSubmitButton(e.target);
      return;
    }
    var body = {
      Devices:this.items,
      ImportedFileId:this.importedFileId
    };
    this.httpService.request({
      method: "post",
      url: "devices/import-devices",
      bodyObject: body,
      loadingEffectElement: e.target.closest("form")
    })
    .subscribe(
      res => {
        if (res.isOk) {
          let successMsg = res.handled.successMsg || "Successful";
          this.triggerEvent(this.form.value);
          this.growlService.growl({
            title: "Success",
            type: "notice",
            message: successMsg
          });
        } else {
          this.growlService.handleError(res);
        }
      },
      err => {
        this.growlService.handleError(err);
      }
    );

  }

  triggerEvent(data) {
    data.index = this.index;
    this.submitEvent.emit(data);
    this.bsModalRef.hide();
  }

}
