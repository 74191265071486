import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-device-checklist-item-details-modal',
  templateUrl: './device-checklist-item-details-modal.component.html',
  styleUrls: []
})
export class DeviceCheckListItemDetailsModalComponent implements OnInit {

  title: string;
  deviceCheckListItem: any;
  resource: string;
  form: FormGroup;

  protected commonDataService: CommonDataService;

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);
    this.form = new FormGroup({

    });
  }

  ngOnInit() {
    console.log(this.deviceCheckListItem);
  }



  onSubmit(e) {
    this.triggerEvent();
  }


  triggerEvent() {
    this.bsModalRef.hide();
  }

}
