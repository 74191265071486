import { Component, OnInit, Input, NgModule } from "@angular/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import * as sharedMethods from "../../../shared-methods";
@Component({
  selector: "app-card-footer-actions",
  templateUrl: "./card-footer-actions.component.html",
  styles: []
})
@NgModule({
  imports: [BsDropdownModule.forRoot()]
})
export class CardFooterActionsComponent implements OnInit {
  @Input() isVisible: boolean = true;
  @Input() isFilteredReport: boolean = false;
  @Input() printButtons: any[] = [];
  @Input() leftButtonText = "Reset";
  @Input() rightButtonText = "Save";

  items = [];
  constructor() {}
  ngOnInit() {}
  ngOnChanges() {
    if (!this.printButtons) {
    } else {
      if (this.items.length < 1) {
        this.items = this.printButtons;
      }
    }
  }

  onChangeReport(val) {
    return sharedMethods.onPrint(val.selector, val.showLogo, val.isLandscape);
  }
}
