import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


import { GrowlService } from '../../services/growl.service';

import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'reset-password-link.component.html'
})
export class ResetPasswordLinkComponent {
  form: FormGroup;
  formSubmitted = false;
  isFormChecked = false;
  queryParams: any;
  buttonLoading = false;
  isLinkSent = false;

  constructor(
    private growlService: GrowlService,
    private httpService: HttpService,
  ) {
    this.form = new FormGroup({
      'username': new FormControl('', [Validators.required])
    });
  }

  onSubmit() {

    this.isFormChecked = true;
    this.buttonLoading = true;

    const body = {
      userName: this.form.value.username

    };

    this.httpService.request({ method: 'post', url: "auth/reset-password/send-link", bodyObject: body }).subscribe(
      res => {
        if (res.isOk) {
          this.isLinkSent = true;
        } else {
          this.buttonLoading = false;
          this.growlService.handleError(res);
        }
      },
      err => {
        this.buttonLoading = false;
        this.growlService.handleError(err);
      }
    );
  }
}
