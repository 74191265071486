//
// ===== File globals.ts
//
import { environment } from "../environments/environment";

console.log("environment Info:" + JSON.stringify(environment));

export const API: string = environment.apiUrl;
export const CDN: string = environment.cdnUrl;

export const localAPI = "assets/data/";
// export const isLocalAPI = !environment.production;
export const isLocalAPI = environment.local;

export const loadingBarBufferTime = 0; // ms
// export const acceptedEmails = window['config'].acceptedEmails;
export const baseImagePath = environment.baseImagePath; // base image path
