import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-system-group-systems-modal',
  templateUrl: './system-group-systems-modal.component.html',
  styleUrls: []
})
export class SystemGroupSystemsModalComponent implements OnInit {

  title: string;
  item: any;
  selectedVesselId:number;
  index: number;
  pageData: any;
  form: FormGroup;
  public submitEvent: EventEmitter<any> = new EventEmitter();
  isFormChecked = false;
  protected commonDataService: CommonDataService;

  items = [];
  systems = [];

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);

    this.form = new FormGroup({
      systemId: new FormControl("", [Validators.required]),
    });


  }

  ngOnInit() {
    if (this.selectedVesselId != null) {
      this.patchFormValues();

      this.httpService
      .request({
        method: "post",
        url: "systems/fkey-filter",
        bodyObject: {vesselId:this.selectedVesselId},
        loadingEffectElement: ".card-list-table"
      })
      .subscribe(
        res => {
          if (res.isOk) {
            this.systems = res.handled.data;
          }
        },
        err => {
          this.growlService.handleError(err);
        }
      );
    }
  }

  patchFormValues() {
    if (this.item != null) {
      this.form.patchValue({
        systemId: this.item.system.id,
      });
    }

  }

  onSubmit(e) {
    this.isFormChecked = true;
    if (!this.form.valid) {
      sharedMethods.shakeSubmitButton(e.target);
      return;
    }

    this.triggerEvent();

  }

  getSystem() {
    var systemId = this.form.get('systemId').value

    if (systemId != 0)
      this.httpService
        .request({
          method: "get",
          url: "systems/" + systemId
        })
        .subscribe(
          res => {
            if (res.isOk) {
              this.item = res.handled.data;
            } else {
              this.growlService.handleError(res);
            }
          },
          err => {
            this.growlService.handleError(err);
          }
        );
  }

  triggerEvent() {
    this.item.index = this.index;
    this.submitEvent.emit(this.item);
    this.bsModalRef.hide();
  }

}
