import { Directive, ElementRef, HostListener, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef, AfterViewInit, Host, Self, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleGuard } from '../guards/role.guard';

@Directive({
  selector: '[appHasRole]'
})
export class AppHasRoleDirective implements OnInit, OnDestroy {
  // the role the user must have
  @Input("appHasRole") role: string;

  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService
   *   -- will give us access to the roles a user has
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private roleGuard: RoleGuard
  ) {}

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    if (this.roleGuard.canDisplay(this.role)) {
      if (!this.isVisible) {
        // We update the `isVisible` property and add the
        // templateRef to the view using the
        // 'createEmbeddedView' method of the viewContainerRef
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }

  }

  ngOnDestroy() {
  }
}
