import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { API } from '../../globals';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';

@Component({
  selector: "app-upload",
  templateUrl: "./app-upload.component.html",
  styleUrls: ["./app-upload.component.css"]
})
export class AppUploadComponent implements OnInit {
  public progress: number;
  public message: string;
  file:File;
  labelName:string = "Choose File";
  @Input() uploadUrl = "";
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private httpService: HttpService, private http:HttpClient, private growlService: GrowlService) { }

  ngOnInit() {
  }

  fileEvent(fileInput: Event){
    this.file = (<HTMLInputElement>fileInput.target).files[0];
    this.labelName = this.file.name;
  }

  public uploadFile = () => {
    let fileToUpload = this.file;
    if (fileToUpload == null ) return;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.httpService.request({
      method: "post",
      url: this.uploadUrl,
      bodyObject:formData,
      httpOptions:{reportProgress:true, observe:'events'}
    }).subscribe(event => {
      if (event.isOk) {
        if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      } else {
        this.growlService.growlMessage("Error", "error", "There is an error while file upload");
      }


    });
  }
}
