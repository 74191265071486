/*

    BU SERVİS, GİDEN VE GELEN HTTP DATALARINI ORGANİZE EDER. HATALI RESPONSE GELMESİ DURUMUNDA, RESPONSE'A msgId PARAMETRESİ EKLER.
    BÖYLECE ÖN YÜZDE DİL BAĞIMLI HATA MESAJLARINI GÖSTERMEK MÜMKÜN OLUR.

    EK OLARAK, LOCAL ÇALIŞMALARDA, PROD. API'DEN BAĞIMSIZ OLARAK, YEREL JSON DOSYALARI İLE ÇALIŞMAYI SAĞLAR.

*/

import { throwError, Observable, Subscription } from "rxjs";
import { map, catchError } from "rxjs/operators";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams
} from "@angular/common/http";

import * as sharedMethods from "../shared-methods";

import { LocalStorageService } from "./local-storage.service";
import { LoadingService } from "./loading.service";

import * as myGlobals from "../globals";

// import { environment } from '../../environments/environment';

@Injectable()
export class HttpService {
  private subscription: Subscription;
  queryParams: any;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscription = activatedRoute.queryParams.subscribe(queryParam => {
      this.queryParams = queryParam;
    });
  }

  urlHandler(url, isCustomUrl) {
    let _url = isCustomUrl ? url : myGlobals.API + url; // check if custom url
    _url = myGlobals.isLocalAPI && isCustomUrl !== true ? _url + '.json' : _url; // if local, add json extension for local testing

    return _url;
  }

  parameterHandler(parametersObject) {
    const params = new HttpParams({
      fromObject: parametersObject
    });

    return params;
  }

  convertObjectToParameter(obj: object): string {
    const arr = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        arr.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return arr.join("&");
  }

  httpOptionsHandler(withToken, method, bodyObject, parametersObject, httpOptions?) {
    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        }),
        observe: "response",
        params: new HttpParams({
          fromObject: parametersObject
        })
      };
    } else {
      httpOptions.headers = new HttpHeaders();
      httpOptions.observe = "response";
    }

    if (withToken !== false) {
      httpOptions.headers = httpOptions.headers.set("Authorization", "Bearer " + localStorage.getItem("token") || "");
    }

    if (method === "delete" && bodyObject) {
      // delete method'unda body kabul edilmediği için, body'nin headers'tan gönderilmesini sağlar
      httpOptions.headers = httpOptions.headers.set(
        "body",
        JSON.stringify(bodyObject)
      );
    }

    return httpOptions;
  }

  bodyHandler(bodyObject, method) {
    if (
      method === "get" ||
      method === "delete" ||
      method === "get" ||
      method === "head" ||
      method === "options"
    ) {
      // angular http, bazı request'lerde body almadığı için header gönderirken çıkan sıkıntılar için yazıldı. bkz https://angular.io/api/http/Http
      return "";
    }
    return bodyObject || "";
  }

  request(obj: {
    withToken?: boolean;
    method: string;
    url: string;
    parametersObject?: object;
    bodyObject?: object;
    isCustomUrl?: boolean;
    responseWrapKey?: string;
    loadingEffectElement?: string;
    httpOptions?:object
  }) {
    let localMsg;

    if (myGlobals.isLocalAPI && obj.method !== 'get') {
      localMsg = 'http method is changed from ' + obj.method + ' to get. Because of using local json files.';
      obj.method = 'get';
    }

    let url = this.urlHandler(obj.url, obj.isCustomUrl);
    let httpOptions = this.httpOptionsHandler(
      obj.withToken,
      obj.method,
      obj.bodyObject,
      obj.parametersObject,
      obj.httpOptions
    );
    let body = this.bodyHandler(obj.bodyObject, obj.method);

    let args = [url, body, httpOptions].filter(x => x);

    this.loadingService.start();

    if (obj.loadingEffectElement) {
      sharedMethods.loadingHandler(obj.loadingEffectElement, true);
    }
    return this.httpClient[obj.method].apply(this.httpClient, args).pipe(
      map((response: Response) => {
        return this.organizeResponse(response, url, obj);
      }),
      catchError((error: Response) => {
        return throwError(this.organizeResponse(error, url, obj));
      })
    );
    // .map((response: Response) => this.organizeResponse(response, url, obj))
  }

  organizeResponse(res, serviceUrl?, requestObj?) {
    if (requestObj.loadingEffectElement) {
      sharedMethods.loadingHandler(requestObj.loadingEffectElement, false);
    }

    this.loadingService.requestCompleted();

    res.body = res.body || {};

    res.handled = {};

    res.isOk = res.body.isSuccess || false;

    res.handled.data = res.body.data;
    res.handled.pagination = res.body.pagination;
    res.handled.errors = this.responseErrorHandler(res, serviceUrl);
    res.handled.successMsg = res.body.successMsg;
    res.handled.serviceName = serviceUrl.replace(myGlobals.API, "") || "";

    return res;
  }

  responseErrorHandler(response, serviceUrl?) {
    let errorsArray = [];
    let status = response.status;
    if (response.body.errors) {
      errorsArray = response.body.errors;
    } else if (status !== 200) {
      switch (status) {
        case 0:
          // errorsArray = [{ msgId: 'CONNECTION_PROBLEM' }];
          errorsArray = [{ msgId: "Connection issue" }];
          break;
        case 401:
          errorsArray = [{ msgId: "Unauthorized Request" }];
          // errorsArray = [{ msgId: 'UNAUTHORIZED_REQUEST' }];
          this.unAuthorizedRequest(serviceUrl);
          break;
        case 404:
          // errorsArray = [{ msgId: 'NOT_FOUND' }];
          errorsArray = [{ msgId: "Not Found" }];
          break;
        default:
          // errorsArray = [{ msgId: 'UNHANDLED_ERROR' }]; // genel hata mesajı
          errorsArray = [{ msgId: "There is an error. Please try again." }]; // genel hata mesajı
      }
    }

    return errorsArray;
  }

  unAuthorizedRequest(serviceUrl) {
    if (!serviceUrl.includes("login") && !serviceUrl.includes("reset-password")) {
      this.localStorageService.clearLocalStorage();
      if (this.router.url !== "/404") {
        try {
          let redirectUrl = (location.pathname + location.search).substr(1);

          this.queryParams["redirect"] = redirectUrl;
          this.router.navigate(["/login"], { queryParams: this.queryParams });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
}
