import {
  Component,
  forwardRef,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  Host,
  Self,
  Optional,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  SimpleChanges,
  SimpleChange,
  Renderer2
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as sharedMethods from '../../../shared-methods';
import { CommonDataService } from '../../../services/common-data.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: "app-drop-down",
  templateUrl: "./drop-down.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppDropDownComponent),
      multi: true
    }
  ]
})
export class AppDropDownComponent implements OnInit, ControlValueAccessor {

  value:any=null;
  @Input() loading:string=null;
  @Input() items:string=null;
  @Input() fkeyDataService:string = null;
  @Input() parameters=null;
  @Output() onExternalChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('ngSelect') protected _ngSelect: ElementRef;
  get ngSelect(): ElementRef {
    return this._ngSelect;
  }
  constructor(
    private _renderer: Renderer2
  ) {
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.onExternalChange) {
      this.onExternalChange.emit(obj);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChanged(selectedItem: any) {

    this._onChange(selectedItem != null ? selectedItem.id: null);
    this.writeValue(selectedItem != null ? selectedItem.id: null);
  }

  onChangeSelect(selectedItem: any) {
    this.onChanged(selectedItem);
  }

  onSelect(selectedItem: any) {
    this.onChanged(selectedItem);
  }

  onRemove(selectedItem: any) {
    this.onChanged(selectedItem);
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._ngSelect.nativeElement, 'disabled', isDisabled);
  }

}
