import { Component, Input } from "@angular/core";

@Component({
  selector: "app-form-group-child",
  templateUrl: "./app-form-group-child.component.html",
  host: {'class': 'col-md-6'}
})
export class AppFormGroupChildComponent {

}
