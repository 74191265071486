import { Component, Input, HostBinding, OnInit } from "@angular/core";

@Component({
  selector: "app-form-group",
  templateUrl: "./app-form-group.component.html"
})
export class AppFormGroupComponent implements OnInit {

  ngOnInit(): void {
  }

}
