
import {of,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

// Bu guard ile sadece login olmayan kullanıcıların giriş yapabileceği sayfalar belirlenir

@Injectable()
export class UnAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated().pipe(map(e => {
            if (e) {
                this.router.navigate(['/']);
                return false;
            } else {
                return true;
            }
        }),catchError((e) => {
            this.router.navigate(['/']);
            return of(false);
        }),);
    }
}
