import { Directive, AfterViewInit, Input } from '@angular/core';
import { Host, Self, Optional } from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';

import { CommonDataService } from '../../services/common-data.service';
import * as sharedMethods from '../../shared-methods';

@Directive({
  selector: '[appDropDownDirective]'
})
export class DropDownDirective implements AfterViewInit {

  @Input("appDropDownDirective") dropDownFkeyListService: string;
  @Input("appDropDownDirectiveParameters") dropDownFkeyListParameters: string;

  constructor(
    private commonDataService: CommonDataService,
    @Host() @Self() @Optional() public hostSelectComponent : NgSelectComponent
  ) {
  }

  ngAfterViewInit() {
    this.loadList();
  }

  loadList() {
    sharedMethods.fillNgSelect(this.commonDataService, this.dropDownFkeyListService, this.hostSelectComponent, this.dropDownFkeyListParameters);
  }

}
