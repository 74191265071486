import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { GrowlService } from '../../services/growl.service';

import * as sharedMethods from '../../shared-methods';
import { DefaultLayoutComponent } from '../../containers';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  loginForm: FormGroup;
  formSubmitted = false;
  isFormChecked = false;
  queryParams: any;
  buttonLoading = false;

  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private growlService: GrowlService,
    private defaultLayoutComponent: DefaultLayoutComponent
  ) {
    this.loginForm = new FormGroup({
      // 'email': new FormControl('', [Validators.required, sharedMethods.isEmail]),
      'username': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required])
    });

    this.subscription = activatedRoute.queryParams.subscribe(
      queryParam => {
        this.queryParams = queryParam;
      }
    );
  }

  ngOnInit() { }

  onSubmit() {

    this.isFormChecked = true;
    this.buttonLoading = true;

    const body = {
      userName: this.loginForm.value.username,
      password: this.loginForm.value.password

    };

    this.authService.loginUser(body, 'auth/token', this.afterLogin.bind(this));

  }


  afterLogin(obj) {
    this.buttonLoading = false;
    if (obj.status === 'expire') {
      this.growlService.growl({
        title: "Error",
        type: "error",
        message: "Your password is expired"
      });
    } else {
      if (obj.status === 'error') {
        let error = obj.response.error.errors[0];
        let msg = error.message;
        this.growlService.growl({
          title: "Error",
          type: "error",
          message: msg != undefined ? msg : "Username or Password is wrong..!"
        });
      } else {
        if (this.queryParams.redirect) {
          this.defaultLayoutComponent.loadMenu();
          // this.router.navigate([this.queryParams.redirect]);
          // window.location.replace(decodeURIComponent(this.queryParams.redirect));
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/']);
        }
      }
    }


  }

}
