import { DeviceCheckListItemDetailsModalComponent } from './../../modals/device-checklist-item-details/device-checklist-item-details-modal.component';
import { BatchDevicesModalComponent } from './../../modals/batch-devices-modal/batch-devices-modal.component';
import { AuditModalComponent } from './../../modals/audit-modal/audit-modal.component';
import { RoleSelectModalComponent } from '../../modals/role-select-modal/role-select-modal.component';
import { UserSelectModalComponent } from './../../modals/user-select-modal/user-select-modal.component';
import { CheckListsItemModalComponent } from './../../modals/checklists-item-modal/checklists-item-modal.component';
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AddToFavoritesComponent } from "./add-to-favorites/add-to-favorites.component";
import { DatePickerComponent } from "../../components/form-components/date-picker/date-picker.component";

// Libraries
// Not: TypeaheadModule (Autocomplete) yerine, ng-select'nin typeahed'i kullanılıyor. İkisi eklendiğinde typeahead directive'i ile çakışıyor
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TabsModule } from "ngx-bootstrap/tabs";
import { OrderModule } from "ngx-order-pipe";
import { NgSelectModule } from "@ng-select/ng-select";
import { FileUploadModule } from "ng2-file-upload";
import { NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";

// Directives

import { ValidationErrorsDirective } from "../../directives/validation-errors.directive";
import { HighlightDirective } from "../../directives/highlight.directive";
import { AppDropDownComponent } from "../../components/form-components/drop-down/drop-down.component";
import { DropDownDirective } from "../../directives/dropdown-data/drop-down.directive";
import { AppHasRoleDirective } from "../../directives/app-has-role.directive";
import { AutoCompleteMenuDirective } from "../../directives/auto-complete-menu.directive";

// COMPONENTS
import { DefaultLayoutComponent } from "../../containers";
import { AppLabeledComponent } from "../../components/form-components/form-group/app-labeled.component";
import { AppInputComponent } from "../../components/form-components/input/app-input.component";
import { AppLabeledInputComponent } from "../../components/form-components/input/app-labeled-input.component";
import { CardFooterComponent } from "../../components/form-components/card-footer/card-footer.component";
import { CardHeaderComponent } from "../../components/form-components/card-header/card-header.component";
import { CardFormComponent } from "../../components/form-components/card-form/card-form.component";
import { RatingInputComponent } from "../../components/form-components/rating-input/rating-input.component";
import { AppLabeledDropDownComponent } from "../../components/form-components/drop-down/labeled-drop-down.component";
import { CardFooterBackComponent } from "../../components/form-components/card-footer/card-footer-back.component";
import { CardFormDetailComponent } from "../../components/form-components/card-form-detail/card-form-detail.component";
import { CardButtonBackComponent } from "../../components/form-components/card-button/card-button-back.component";
import { AppFormGroupComponent } from "../../components/form-components/form-group/app-form-group.component";
import { AppFormGroupChildComponent } from "../../components/form-components/form-group/app-form-group-child.component";
import { AppPaginationComponent } from "../../components/form-components/pagination/app-pagination.component";
import { AppCardTableComponent } from "../../components/form-components/card-table/app-card-table.component";
import { AppCardTablePageComponent } from "../../components/form-components/card-table/app-card-table-page.component";
import { ValidationComponent } from "../../components/form-components/validation/validation.component";
import { AppDynamicColumnsComponent } from "../../components/form-components/dynamic-columns/app-dynamic-columns.component";
import { CardFooterActionsComponent } from "../../components/form-components/card-footer/card-footer-actions.component";

// MODALS
import { AppGroupFormGroupComponent } from '../../components/form-components/form-group/app-group-form-group.component';
import { AppFormGroupLineComponent } from '../../components/form-components/form-group/app-form-group-line.component';
import { ImportDevicesModalComponent } from '../../modals/import-devices-modal/import-devices-modal.component';
import { AppUploadComponent } from '../../components/upload/app-upload.component';
import { SystemGroupSystemsModalComponent } from '../../modals/system-group-systems-modal/system-group-systems-modal.component';

//

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    OrderModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    NgSelectModule,
    FileUploadModule,
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    AddToFavoritesComponent,
    ValidationErrorsDirective,
    CardFormComponent,
    CardFooterComponent,
    CardHeaderComponent,
    AppDropDownComponent,
    HighlightDirective,
    CardFooterBackComponent,
    CardFormDetailComponent,
    ValidationErrorsDirective,
    DatePickerComponent,
    AppInputComponent,
    AppLabeledComponent,
    AppGroupFormGroupComponent,
    AppFormGroupLineComponent,
    AppLabeledInputComponent,
    RatingInputComponent,
    DropDownDirective,
    AppLabeledDropDownComponent,
    CardButtonBackComponent,
    AppFormGroupComponent,
    AppFormGroupChildComponent,
    AppPaginationComponent,
    ValidationComponent,
    AppCardTableComponent,
    AppCardTablePageComponent,
    RoleSelectModalComponent,
    AppDynamicColumnsComponent,
    AppHasRoleDirective,
    CardFooterActionsComponent,
    AutoCompleteMenuDirective,
    ImportDevicesModalComponent,
    CheckListsItemModalComponent,
    UserSelectModalComponent,
    AuditModalComponent,
    BatchDevicesModalComponent,
    SystemGroupSystemsModalComponent,
    DeviceCheckListItemDetailsModalComponent,
    AppUploadComponent
  ],
  providers: [
    // FavoritesService
    DatePipe
  ],
  exports: [
    AddToFavoritesComponent,
    CardFormComponent,
    CardFooterComponent,
    CardHeaderComponent,
    AppDropDownComponent,
    HighlightDirective,
    OrderModule,
    FormsModule,
    CardFooterBackComponent,
    ReactiveFormsModule,
    PaginationModule,
    TabsModule,
    CardFormDetailComponent,
    NgSelectModule,
    FileUploadModule,
    ValidationErrorsDirective,
    NgxMaskModule,
    ValidationErrorsDirective,
    BsDatepickerModule,
    DatePickerComponent,
    ModalModule,
    AppInputComponent,
    AppLabeledComponent,
    AppGroupFormGroupComponent,
    AppFormGroupLineComponent,
    AppLabeledInputComponent,
    RatingInputComponent,
    DropDownDirective,
    AppLabeledDropDownComponent,
    CardButtonBackComponent,
    AppFormGroupComponent,
    AppFormGroupChildComponent,
    AppPaginationComponent,
    AppCardTableComponent,
    AppCardTablePageComponent,
    ValidationComponent,
    AppDynamicColumnsComponent,
    AppHasRoleDirective,
    CardFooterActionsComponent,
    AutoCompleteMenuDirective,
    AppUploadComponent
  ],
  entryComponents: [
    RoleSelectModalComponent,
    ImportDevicesModalComponent,
    CheckListsItemModalComponent,
    UserSelectModalComponent,
    AuditModalComponent,
    BatchDevicesModalComponent,
    SystemGroupSystemsModalComponent,
    DeviceCheckListItemDetailsModalComponent,
    AppUploadComponent
  ]
})
export class SharedModule {}
