import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDataService } from '../../services/common-data.service';
import { HttpService } from '../../services/http.service';
import { GrowlService } from '../../services/growl.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInjector } from '../../services/app-injector.service';
import * as sharedMethods from "../../shared-methods";

@Component({
  selector: 'app-audit-modal',
  templateUrl: './audit-modal.component.html',
  styleUrls: []
})
export class AuditModalComponent implements OnInit {

  title: string;
  id: number;
  resource: string;
  form: FormGroup;

  protected commonDataService: CommonDataService;

  items = [];
  fields = [
    { header: "Created By", name: "createdBy" },
    { header: "Created Date", name: "createdDate" },
    { header: "State Name", name: "stateName" }
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private httpService: HttpService,
    private growlService: GrowlService
  ) {
    const injector = AppInjector.getInjector();
    this.commonDataService = injector.get(CommonDataService);
    this.form = new FormGroup({

    });
  }

  isChanged(currentVal, previousVal): boolean {

    console.log(currentVal + " ---- " + previousVal);
    if (!previousVal || currentVal !== previousVal) {
      return true;
    }
    return false;
  }

  applyStyles(currentVal, previousVal) {
    if (this.isChanged(currentVal, previousVal))
      return {'color' : 'green', 'width':'150px'};
    else return {'width':'150px'};
}


  ngOnInit() {
    if (this.id > -1)
      this.httpService
        .request({
          method: "get",
          url: this.resource +"/" + this.id + "/audit-entries"
        })
        .subscribe(
          res => {
            if (res.isOk) {
              var auditEntries= res.handled.data;
              if (auditEntries.length < 1) {
                this.growlService.growlMessage("Response","error","Audit information is not found");
                this.bsModalRef.hide();
                return;
              }
              var fieldSetupIsDone = false;
              auditEntries.forEach(auditEntry => {
                var auditEntryProperties = auditEntry["auditEntryProperties"];
                auditEntryProperties.forEach(auditEntryProperty => {
                  if (auditEntryProperty["propertyName"] != "Id") {
                    if (!fieldSetupIsDone) {
                      this.fields.push( { header : auditEntryProperty["propertyName"], name:auditEntryProperty["propertyName"] } );
                    }
                    auditEntry[auditEntryProperty["propertyName"]] = auditEntryProperty["newValue"];
                  }
                });

                fieldSetupIsDone = true;
              });
              this.items = auditEntries;

            } else {
              this.growlService.handleError(res);
            }
          },
          err => {
            this.growlService.handleError(err);
          }
        );
  }



  onSubmit(e) {

    this.triggerEvent();

  }


  triggerEvent() {
    this.bsModalRef.hide();
  }

}
