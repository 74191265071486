import { Component, Input } from "@angular/core";

@Component({
  selector: "app-card-form",
  templateUrl: "./card-form.component.html"
})
export class CardFormComponent {
  @Input() isVisibleActions: boolean = true;
  @Input() isFilteredReport: boolean = false;
  @Input() printButtons = [];
  @Input() cardHeaderName: string = "";
  @Input() columnSize: string = "col-sm-12";
}
