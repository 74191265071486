import { of, Observable } from "rxjs";
import { HttpService } from "./http.service";

import { Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { GrowlService } from "./growl.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class CommonDataService {
  queryParams: any;
  subscription: Subscription;
  constructor(
    private httpService: HttpService,
    private growlService: GrowlService,
    private authService: AuthService
  ) {}

  currenciesData = null;
  taxesData = null;
  statusData = null;
  workTypeData = null;
  kdvData = null;
  cariTipiData = null;
  cariHareketTipiData = null;
  bankaAdiData = null;
  birimlerData = null;
  accountTypeData = null;
  muhasebeKodlariData = null;
  taxAuthorityData = null;
  TenderTypeData = null;
  CurrentAccountData = null;
  TenderProcedureData = null;
  TenderStatusData = null;
  PurchaseStatusData = null;
  SortData = null;
  responsibilityCenterData = null;
  cashbookAccountsFkeyListData = null;

  getappContact(refresh?) {
    return this.dataGetHandler(
      "contactIdData",
      "contacts/fkey-list",
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getMenu(refresh?) {
    return this.dataGetHandler("MenuData", "menu", false, refresh, data => {
      return data;
    });
  }

  getProjectsFKeyListData(refresh?) {
    return this.dataGetHandler(
      "ProjectsFkeyData",
      "projects/fkey-autocomplete-list",
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getSystemsFKeyListData(refresh?) {
    var url = "systems/fkey-autocomplete-list";
    return this.dataGetHandler(
      "SystemsFkeyData",
      url,
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getVesselsFKeyListData(filter?, refresh?) {
    var url = "vessels/fkey-autocomplete-list";
    //fkey-autocomplete-list
    return this.dataGetHandler(
      "VesselsFkeyData",
      url,
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getChecklistItemsFkeyListData(refresh?) {
    return this.dataGetHandler(
      "ChecklistItemsFkeyData",
      "checklist-items/fkey-autocomplete-list",
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getChecklistsFkeyListData(refresh?) {
    return this.dataGetHandler(
      "ChecklistsFkeyData",
      "checklists/fkey-autocomplete-list",
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getChecklistGroupItemsFkeyList(refresh?, checkListId?) {
    var url = "";
    if (checkListId) {
      url = url + "checklists/" + checkListId + "/items";
    } else {
      return;
    }
    return this.dataGetHandler(
      "ChecklistItemsFkeyData",
      url,
      false,
      refresh,
      data => {
        return data;
      }
    );
  }

  getDeviceStatusData(refresh?) {
    return this.dataGetHandler(
      "DeviceStatusData",
      "assets/data/common-data/device-status-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getSystemStatusData(refresh?) {
    return this.dataGetHandler(
      "SystemStatusData",
      "assets/data/common-data/system-status-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getIconData(refresh?) {
    return this.dataGetHandler(
      "IconData",
      "assets/data/common-data/icon-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getProjectStatusData(refresh?) {
    return this.dataGetHandler(
      "ProjectStatusData",
      "assets/data/common-data/project-status-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getUserStatusData(refresh?) {
    return this.dataGetHandler(
      "UserStatusData",
      "assets/data/common-data/user-status-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getCheckListItemStatusData(refresh?) {
    return this.dataGetHandler(
      "CheckListItemStatusData",
      "assets/data/common-data/checklist-item-status-data.json",
      true,
      refresh,
      data => {
        return data;
      }
    );
  }

  getUsersFkeyListData(refresh?) {
    //api qden kullanıcıları bu url ile gelecek
    return this.dataGetHandler(
      "UsersFkeyData", // eklendi
      "users/fkey-list", //" + localStorage.user + "/
      false,
      refresh,
      data => {
        return data.map(item => {
          item.id = item.id;
          item.text = item.text;
          return item;
        });
      }
    );
  }

  getRolesFkeyListData(refresh?) {
    //api qden kullanıcıları bu url ile gelecek
    return this.dataGetHandler(
      "RolesFkeyData", // eklendi
      "roles/fkey-list", //" + localStorage.user + "/
      false,
      refresh,
      data => {
        return data.map(item => {
          item.id = item.id;
          item.text = item.text;
          return item;
        });
      }
    );
  }

  getAutoCompleteMenuData(term) {
    return this.dataGetHandler(
      "menuData",
      "menu/fkey-autocomplete-list/" + term,
      false,
      true,
      data => {
        return data;
      }
    );
  }

  //#endregion

  dataGetHandler(
    dataName: string,
    url: string,
    isCustomUrl = false,
    refresh?: Boolean,
    dataHandlerFunction?: Function,
    method?: string,
    body?: Object
  ) {
    if (!this[dataName] || refresh) {
      return this.dynamicDataGetter(
        dataName,
        url,
        isCustomUrl,
        dataHandlerFunction,
        method,
        body
      );
    } else {
      return of(this[dataName]);
    }
  }

  dynamicDataGetter(
    dataName,
    url,
    isCustomUrl = false,
    cast?,
    method = "get",
    body?
  ) {
    let requestObj = {
      method: method,
      url: url,
      isCustomUrl: isCustomUrl
    };

    if (body) {
      requestObj["body"] = body;
    }
    return new Observable(observer => {
      this.httpService.request(requestObj).subscribe(
        res => {
          if (res.isOk) {
            this[dataName] = res.handled.data;
            let data = res.handled.data;

            if (cast) {
              data = cast(data);
            }

            observer.next(data);
          } else {
            this.growlService.handleError(res);
            observer.error(res);
          }
        },
        err => {
          this.growlService.handleError(err);
          observer.error(err);
        }
      );
    });
  }
}
