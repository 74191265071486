import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

export let isEmail = function(control): { [s: string]: boolean } {
  if (control) {
    if (!control.value) {
      // null veya boş string check için Validators.Required kullanılmalı. Bu method sadece email doğru mu diye kontrol eder. Boş value da valid'dir.
      return null;
    }
    if (
      !control.value
        .trim()
        .match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
    ) {
      return { noEmail: true }; // bu obje, invalid controller'ın errors objesinde gözükecektir. valid ise gözükmez.
    }
  } else {
    return null;
  }
};

export let isString = function(val): boolean {
  return typeof val === "string" || val instanceof String ? true : false;
};

export let getTaxValue = function(dataObj) {
  let taxValue = null; // gelen obje {name: null, percent: null, id: null} şeklinde olduğu için, required olmasına rağmen valid gözükmesini önlemek adına bu if else durumu var

  if (dataObj.tax) {
    taxValue = dataObj.tax.percent ? dataObj.tax : null;
  }

  return taxValue;
};

export let checkTcNo = function(control) {
  if (control) {
    let value = control.value.toString();
    let isEleven = /^[0-9]{11}$/.test(value);
    let totalX = 0;
    for (let i = 0; i < 10; i++) {
      totalX += Number(value.substr(i, 1));
    }
    const isRuleX = totalX % 10 == value.substr(10, 1);
    let totalY1 = 0;
    let totalY2 = 0;
    for (let i = 0; i < 10; i += 2) {
      totalY1 += Number(value.substr(i, 1));
    }
    for (let i = 1; i < 10; i += 2) {
      totalY2 += Number(value.substr(i, 1));
    }
    const isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
    if (isEleven && isRuleX && isRuleY) {
      return null;
    } else {
      return { tcNoValidation: false };
    }
  }
};

export let detectIE = function(): any {
  let ua = window.navigator.userAgent;

  let msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  let trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  let edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
};

export let Countdown = function(duration, el) {
  this.finishEvent = new EventEmitter();

  this.duration = duration;
  this.el = el;

  this.canTimerContinue = true;
  this.totalSeconds = duration;

  this.stopTimer = () => {
    this.canTimerContinue = false;
  };

  this.startTimer = () => {
    this.canTimerContinue = true;
    this.tick();
  };

  this.tick = () => {
    let minutes, seconds;

    setTimeout(() => {
      minutes = Math.floor(this.totalSeconds / 60);
      seconds = this.totalSeconds % 60;

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      el.textContent = minutes + ":" + seconds;
      this.totalSeconds = this.totalSeconds - 1;
      if (this.totalSeconds < 0) {
        this.finishEvent.emit(this);
      } else if (this.canTimerContinue) {
        this.tick();
      }
    }, 1000);
  };
};

export let triggerChangeEvent = function(el): void {
  var customEvent = document.createEvent("Event");

  customEvent.initEvent("change", true, true);
  el.dispatchEvent(customEvent);
  customEvent.initEvent("input", true, true);
  el.dispatchEvent(customEvent);
};

export let loadingHandler = function(el, showLoading): void {
  // el=id or html element | add or remove loading class form element
  let elementType = typeof el === "string" ? "selector" : "element";
  let element = null;

  if (elementType === "selector") {
    element = document.querySelector(el);
  } else {
    element = el;
  }

  if (!element) {
    return;
  }

  if (showLoading) {
    element.classList.add("element-loading");
  } else {
    element.classList.remove("element-loading");
  }
};

export let shakeSubmitButton = function(formElement) {
  formElement
    .querySelector('[type="submit"]')
    .classList.remove("invalid-animation");
  setTimeout(() => {
    formElement
      .querySelector('[type="submit"]')
      .classList.add("invalid-animation");
  }, 150);
};

export let goBack = function() {
  window.history.back();
};

export let clearForm = function(form: FormGroup) {
  form.reset();
};

export let ToDate = function(date: any, returnVal?: any): Date {
  return date ? new Date(date) : returnVal ? returnVal : "";
};

export let fillNgSelect = function(
  commonDataService,
  serviceMethod,
  hostSelectComponent,
  parameters?,
  bindLabel = "text"
) {
    var method;
    if (!serviceMethod) {
      return;
    }
    if (parameters) {
      method = commonDataService[serviceMethod](parameters, true);
    } else {
      method = commonDataService[serviceMethod](true);
    }
    method.subscribe(res => {

    hostSelectComponent.items = [
      // ...hostSelectComponent.items,
      ...res
    ];
    hostSelectComponent.bindLabel = bindLabel;
    hostSelectComponent.bindValue = "id";
    hostSelectComponent.loading = false;
    hostSelectComponent.ngOnChanges({
      items: {
        previousValue: [],
        currentValue: hostSelectComponent.items,
        firstChange: false,
        isFirstChange: () => false
      }
    });
    hostSelectComponent.detectChanges();
  });
};

export let onPrint = function(
  selector: string,
  showLogo?: boolean,
  isLandscape?: boolean,
  header?: string
): boolean {
  let printContents, popupWin, printSection;
  printSection = document.getElementById(selector);
  if (!printSection) return false;
  printContents = printSection.innerHTML;
  popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
  popupWin.document.open();
  popupWin.document.write("<html><head>");
  popupWin.document.write(
    '<link rel="stylesheet" type="text/css" href="../../../../assets/css/style.css" />'
  );
  var visibility = showLogo ? "visible" : "hidden";
  popupWin.document.write(
    '<div align="center" style="visibility: ' + visibility + '">'
  );
  popupWin.document.write(
    '<img src="../../../../assets/img/ibb-logo-m.png" alt="ibblogo" height="62" width="50"></div>'
  );

  //header option added
  if(header != null && header != ""){
    var pageheader = header ? "visible" : "hidden";
    popupWin.document.write(
      '<div align="center" style="visibility: ' + pageheader + '">'
    );
    popupWin.document.write(
      '<p><span style="font-weight:bold">' + header + '</span></p></div>'
    );
  }

  popupWin.document.write("</head>");
  popupWin.document.write('<body onload="window.print();window.close()">');
  var orientation = isLandscape ? "landscape" : "portrait";
  popupWin.document.write(
    '<style type="text/css"> @page { size: A4 ' + orientation + "; }</style>"
  );
  popupWin.document.write(printContents + "</body></html>");
  popupWin.document.close();
  return false;
};
