
import {of, Subscription ,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    private subscription: Subscription;
    queryParams: any;

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.subscription = activatedRoute.queryParams.subscribe(
            queryParam => {
                this.queryParams = queryParam;
            }
        );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated().pipe(map(e => {
            if (e) {
                return true;
            } else {
                let redirectUrl = (location.pathname + location.search).substr(1);

                this.queryParams['redirect'] = redirectUrl;
                this.router.navigate(['/login'], { queryParams: this.queryParams });
                return false;
            }
        }),catchError(() => {
            this.router.navigate(['/login']);
            return of(false);
        }),);
    }
}
