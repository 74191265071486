
import {of, Subscription ,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable()
export class RoleGuard implements CanActivate {

  private subscription: Subscription;
  queryParams: any;
  constructor(private _authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.subscription = this.activatedRoute.queryParams.subscribe(
      queryParam => {
          this.queryParams = queryParam;
      }
    );
  }

  canDisplay(role:string) : boolean {
    const user = this._authService.currentUser();

    if (!user.permissions) {
      user.permissions = [];
    }

    if (user.permissions.findIndex(p => p.code == role)>0) {
      return true;
    } else {
      return false;
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this._authService.currentUser();

    if (!user.permissions) {
      user.permissions = [];
    }
    //this.personService.getPersons().filter(x => x.id == this.personId)[0];
    if (user.permissions.findIndex(p => p.code == next.data.role)>0) {
      return true;
    }

    // navigate to not found page
    if (!this._authService.isAuthenticated()) {
      let redirectUrl = (location.pathname + location.search).substr(1);
      this.queryParams['redirect'] = redirectUrl;
      this.router.navigate(['/login'], { queryParams: this.queryParams });
    } else {
      // navigate to not found page
      this.router.navigate(['/404']);
    }

    return false;
  }

}
