
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styles: []
})
export class CardHeaderComponent implements OnInit {

  constructor() {}

  @Input() cardHeaderName: string;

  ngOnInit() { }

}
