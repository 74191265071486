import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  loginAttemptCount = 0;

  constructor() {
    if (!localStorage.getItem('loginAttempt')) {
      localStorage.setItem('loginAttempt', '0');
    }
    this.loginAttemptCount = Number(localStorage.getItem('loginAttempt'));
    this.getClientId();
  }

  createClientId() {

    let uniqId = String(Math.floor(Math.random() * 9999999) + 999999);
    const timestamp = new Date();
    uniqId = uniqId + String(timestamp.getTime());
    localStorage.setItem('clientId', String(uniqId));
  }

  getClientId() {

    if (!localStorage.getItem('clientId')) {
      this.createClientId();
    }

    return localStorage.getItem('clientId');
  }

  getObject(id: string, encrypted: boolean) {
    if (localStorage.getItem(id) && localStorage.getItem(id) !== 'undefined') {
      if (encrypted) {
        return JSON.parse(atob(localStorage.getItem(id)));

      } else {
        return JSON.parse(localStorage.getItem(id));
      }
    } else {
      return false;
    }
  }

  increaseLoginAttempt() {
    this.loginAttemptCount++;
    localStorage.setItem('loginAttempt', String(this.loginAttemptCount));
  }

  checkShowCaptcha() {
    return this.loginAttemptCount > 2;
  }

  resetLoginAttempCount() {
    localStorage.setItem('loginAttempt', '0');
    this.loginAttemptCount = 0;
  }

  clearLocalStorage(hard?) {

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    if (hard) {
      localStorage.clear();
    }
  }

}
