import { FormGroupDirective } from '@angular/forms';
import { Component, Input, Host, SkipSelf } from '@angular/core';
@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
})
export class ValidationComponent  {
  @Input() controlName: string;
  @Input() errorKey: string;

  constructor(
    @Host() @SkipSelf() private form: FormGroupDirective
  ) { }

  ngOnInit() {
  }
  get isInvalid() {
    let control = this.form.form.get(this.controlName);
    return control.hasError(this.errorKey) && (control.dirty || this.form.submitted);
  }
}
