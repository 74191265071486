import { Component, OnInit, Input } from "@angular/core";
@Component({
  selector: "app-card-footer",
  templateUrl: "./card-footer.component.html",
  styles: []
})
export class CardFooterComponent implements OnInit {
  @Input() isVisibleActions: boolean = true;
  @Input() isFilteredReport: boolean = false;
  @Input() printButtons = [];
  constructor() {}
  ngOnInit() {}
}
