import {
  Component,
  forwardRef,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AppInputComponent } from "./app-input.component";

@Component({
  selector: "app-labeled-input",
  templateUrl: "./app-labeled-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppLabeledInputComponent),
      multi: true
    }
  ]
})
export class AppLabeledInputComponent implements OnInit, ControlValueAccessor {
  value: string;
  @Input() ngClass: string ="";
  @Input() autocomplete: string ="off";
  @Input() min: string = "";
  @Input() max: string = "";
  @Input() maxLength: string = "";
  @Input() disabled = false;
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() id: string = "";
  @Input() type: string = "text";
  @Input() isRequired: boolean = false;
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @ViewChild("input") private _input: AppInputComponent;

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._input.setDisabledState(isDisabled);
  }

  ngAfterViewInit(){
    const self = this;
    this._input.inputElement.nativeElement.addEventListener("change", function(
      event
    ) {
      self._onChange(this.value);
    });
    this._input.inputElement.nativeElement.addEventListener("keyup", function(
      event
    ) {
      self._onChange(this.value);
    });
    this._input.inputElement.nativeElement.addEventListener("blur", function(
      event
    ) {
      self.onBlur.emit();
    });
  }

  ngOnInit() {

  }
}
