import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { GrowlService } from '../../services/growl.service';

import * as sharedMethods from '../../shared-methods';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'reset-password.component.html'
})
export class ResetPasswordComponent {
  form: FormGroup;
  isFormChecked = false;
  queryParams: any;
  buttonLoading = false;
  isResetFormVisible = false;
  isResetPasswordSuccess=false;

  private subscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private growlService: GrowlService,
    private httpService: HttpService,
  ) {
    this.form = new FormGroup({
      'resetPassword': new FormControl('', [Validators.required]),
      'confirmPassword': new FormControl('', [Validators.required])
    });

    this.subscription = activatedRoute.queryParams.subscribe(
      queryParam => {
        this.queryParams = queryParam;
        var body = {linkGuid : this.queryParams.guid}
        this.httpService.request({ method: 'post', url: "auth/reset-password/check-guid", bodyObject: body }).subscribe(
          res => {
            if (res.isOk) {
              this.isResetFormVisible = true;
            } else {
              this.growlService.handleError(res);
            }
          },
          err => {
            this.growlService.handleError(err);
          }
        );

      }
    );
  }

  private MatchPasswords(password1, password2) {
    if (password1 !== password2) {
      this.growlService.growl({
        title: "Error",
        type: "warning",
        message: "Passwords are not same."
      });
      return false;
    }
    return true;
  }

  onSubmit() {

    let password1 = this.form.get("resetPassword").value;
    let password2 = this.form.get("confirmPassword").value;
    var match = this.MatchPasswords(password1, password2);
    if (!match) {
      return;
    }

    this.isFormChecked = true;
    this.buttonLoading = true;

    const body = {
      linkGuid:this.queryParams.guid,
      resetPassword:password1,
      confirmPassword:password2
    };

    this.httpService.request({ method: 'post', url: "auth/reset-password", bodyObject: body }).subscribe(
      res => {
        if (res.isOk) {
          this.isResetPasswordSuccess = true;
          this.isResetFormVisible = false;
        } else {
          this.growlService.handleError(res);
        }
        this.buttonLoading = false;
      },
      err => {
        this.buttonLoading = false;
        this.growlService.handleError(err);
      }
    );

  }

}
