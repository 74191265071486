import { Component, OnInit } from '@angular/core';
import { FavoritesService } from '../../../services/favorites.service';

@Component({
  selector: 'app-add-to-favorites',
  templateUrl: './add-to-favorites.component.html'
})
export class AddToFavoritesComponent implements OnInit {

  constructor(
    public favoritesService: FavoritesService
  ) { }

  ngOnInit() {
  }


  addToFavorites(e) {
    this.favoritesService.addToFavorites(this.favoritesService.breadcrumbs, e);
  }

  removeFromFavorites(obj) {
    // this.favoritesService.removeFromFavorites(obj);
  }

}
