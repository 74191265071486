import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-labeled',
  templateUrl: './app-labeled.component.html'
})
export class AppLabeledComponent {
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() isRequired: boolean = false;
  @Input() isDoubleComponentLabel: boolean = false;
}
