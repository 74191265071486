import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class GrowlService {

  constructor() { }

  growlMessage(title, type, message) {
    this.growl({ title: title, type: type, message: message });
  }

  growl(data) {

    if (typeof $.growl[data.type] === 'undefined') {
      data.type = 'warning';
    }

    if (data.type) {
      return $.growl[data.type](data);
    }

    $.growl($.extend(
      data,
      ($(this).hasClass('growl-demo-static') ? { duration: 9999 * 9999 } : {})
    ));
  }

  handleError(errorResponse, title?) {

    let message :any;

    try {
      var serviceErrors = errorResponse.error.errors;
      if (serviceErrors && Array.isArray(serviceErrors)) {
        message = serviceErrors.map(error => {
          if (error.id || error.message) {
            return error.id || error.message; // translate edip return edilecek
          } else {
            return error.message;
          }
        })
        ;
      } else {
        var res = errorResponse.handled.errors;
          if (Array.isArray(res)) {
                message = res.map(error => {
                  if (error.id || error.msgId) {
                    return error.id || error.msgId; // translate edip return edilecek
                  } else {
                    return error.message;
                  }
                }).join('<br><br>');
          } else {
                message = errorResponse.handled.errors.msgId; // bu değer httpservice tarafından otomatik ekleniyor
          }
      }

    } catch (error) {
    }
    message.forEach(element => {
      this.growl({ title: title || 'Error', type: 'error', message: element  || 'An error occurred!' });
    });

  }

  handleSuccess(successResponse, title?) {
    let message = null;
    var res = successResponse.handled.errors;

    if (Array.isArray(res)) {
      message = res.map(error => {
        if (error.id || error.msgId) {
          return error.id || error.msgId; // translate edip return edilecek
        } else {
          return error.message;
        }
      }).join('<br><br>');
    } else {
      message = successResponse.handled.data.successMsgId; // bu değer httpservice tarafından otomatik ekleniyor
    }

    this.growl({ title: title || 'Success ', type: 'notice', message: message  || '' });
  }

}
