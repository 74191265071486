import { Component, Input } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup
} from "@angular/forms";

@Component({
  selector: 'app-card-form-detail',
  templateUrl: './card-form-detail.component.html',
})
export class CardFormDetailComponent  {

  @Input() cardHeaderName: string = "";
  @Input() columnSize: string = "col-sm-6";
}
