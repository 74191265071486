import { ResetPasswordLinkComponent } from './views/reset-password-link/reset-password-link.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard"; // sadece login olan görebilir
import { UnAuthGuard } from "./guards/un-auth.guard"; // sadece login olmayan görebilir

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
    data: {}
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404"
    }
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500"
    }
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page"
    },
    canActivate: [UnAuthGuard]
  },
  {
    path: "reset-password-link",
    component: ResetPasswordLinkComponent,
    data: {
      title: "Send Reset Password Link"
    }
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password"
    }
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home"
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "inspection",
        loadChildren: "./views/inspection/inspection.module#InspectionModule"
      },
      {
        path: "definitions",
        loadChildren: "./views/definitions/definitions.module#DefinitionsModule"
      },
      {
        path: "dashboard",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule"
      },
      {
        path: "favorites",
        loadChildren: "./views/favorites/favorites.module#FavoritesModule"
      },
      {
        path: "management",
        loadChildren: "./views/management/management.module#ManagementModule"
      },
      {
        path: "reports",
        loadChildren: "./views/reports/reports.module#ReportsModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "404"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
