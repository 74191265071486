import { Router } from '@angular/router';
import { ResetPasswordLinkComponent } from './views/reset-password-link/reset-password-link.component';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import * as Sentry from '@sentry/angular';
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe
} from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from "./app.component";
// import { AddToFavoritesComponent } from './views/shared/add-to-favorites/add-to-favorites.component';

// Import containers
import { DefaultLayoutComponent } from "./containers";

// Unique pages
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

const APP_CONTAINERS = [
  DefaultLayoutComponent
  // AddToFavoritesComponent
];

// Import angular modules
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from "@coreui/angular";

import { SharedModule } from "./views/shared/shared.module";

// Import routing module
import { AppRoutingModule } from "./app.routing";
import { AuthGuard } from "./guards/auth.guard";
import { UnAuthGuard } from "./guards/un-auth.guard";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// Services
import { AuthService } from "./services/auth.service";
import { HttpService } from "./services/http.service";
import { LocalStorageService } from "./services/local-storage.service";
import { LoadingService } from "./services/loading.service";
import { GrowlService } from "./services/growl.service";
import { FavoritesService } from "./services/favorites.service";

import { defineLocale } from "ngx-bootstrap/chronos";
import { trLocale } from "ngx-bootstrap/locale";
import { RoleGuard } from "./guards/role.guard";
// The string MUST be lower case - even though the examples give it as enGb
defineLocale("tr", trLocale);

import { registerLocaleData } from "@angular/common";
import localeTr from "@angular/common/locales/tr";
import { LOCALE_ID } from "@angular/core";
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';


registerLocaleData(localeTr, "tr");
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule, // diğer alt modüller (dashboard favorites vs) buradan ekleniyor
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    // SlimLoadingBarModule.forRoot(),
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    LoadingBarHttpClientModule,
    BrowserAnimationsModule,

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    ResetPasswordComponent,
    ResetPasswordLinkComponent,
    RegisterComponent


  ],
  providers: [
    AuthGuard,
    UnAuthGuard,
    RoleGuard,
    AuthService,
    HttpService,
    LocalStorageService,
    GrowlService,
    LoadingService,
    FavoritesService,
    DefaultLayoutComponent,
    DatePipe,
    { provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: LOCALE_ID, useValue: "tr-tr" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
