import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup
} from "@angular/forms";

@Component({
  selector: "app-dynamic-columns",
  templateUrl: "./app-dynamic-columns.component.html"
})
export class AppDynamicColumnsComponent implements OnInit {
  constructor(private _renderer: Renderer2) {}

  @Input() dynamicColumns:any[] = [];
  @Input() dynamicFormGroup;
    ngOnInit() {
    }

    dynamicMultiSelectColumns() {
      return this.dynamicColumns.filter(function(dy) {
        return dy.columnType==='MultiSelect';
      });
    }

    dynamicTextColumns() {
      return this.dynamicColumns.filter(function(dy) {
        return dy.columnType === 'Text';
      });
    }

}
