// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var url = window["config"]
  ? window["config"].url
  : "https://stagingapi.instacommcloud.de/";

export const environment = {
  envName: "staging",
  production: false,
  local: false,
  apiUrl: "https://stagingapi.instacommcloud.de/",
  cdnUrl: "https://stagingapi.instacommcloud.de/",
  baseImagePath: "https://stagingapi.instacommcloud.de/"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
