import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-form-group',
  templateUrl: './app-group-form-group.component.html',
  styleUrls : ["./app-group-form-group.component.scss"]
})
export class AppGroupFormGroupComponent {
  @Input() label: string = '';
}
