import {
  Component,
  forwardRef,
  ElementRef,
  ViewChild,
  Input,
  Renderer2
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./app-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppInputComponent),
      multi: true
    }
  ]
})
export class AppInputComponent implements ControlValueAccessor {
  constructor(private _renderer: Renderer2) {}

  value: string = "";
  @Input() ngClass: string ="";
  @Input() autocomplete: string ="";
  @Input() disabled:boolean = false;
  @Input() isRequired:boolean = false;
  @Input() type: string = "text";
  @Input() id: string;
  @Input() placeholder: string = "";
  @Input() maxLength: string = "";
  @Input() max: string = "";
  @Input() min: string = "";
  @Input() mask: string = "";
  @ViewChild("inputElement") private _inputElement: ElementRef;
  get inputElement(): ElementRef {
    return this._inputElement;
  }
  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._inputElement.nativeElement,
      "disabled",
      isDisabled
    );
  }

  onChange(event: any) {
    this._onChange(event.target.value);
  }
  onKeyup(event: any) {
    this._onChange(event.target.value);
  }
  onBlur(event: any) {
    this._onTouched();
  }
}
