
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-card-footer-back',
  templateUrl: './card-footer-back.component.html',
  styles: []
})
export class CardFooterBackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
