import { Component, forwardRef, Input, ChangeDetectorRef, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
 
  selectedDate = null;


  constructor(
    private cdr: ChangeDetectorRef,
    private _localeService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this._localeService.use("tr");
  }

  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input() disabled = false;
  @Input() minDate = "";
  @Input() maxDate = "";
  @Input() placeholder = "";
  @Input() format = "DD/MM/YYYY";
  // @HostBinding('style.opacity')
  // get opacity() {
  //   return this.disabled ? 0.25 : 1;
  // }

  // Function to call when the rating changes.
  onChange = date => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  get value() {
    return this.selectedDate;
  }

  onValueChange(date) {
    if (!this.disabled && date && date != "") {
      this.writeValue(date);
    }
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(date): void {
    this.selectedDate = date;
    this.cdr.detectChanges();
    this.onChange(this.selectedDate);
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
