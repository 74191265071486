
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-card-button-back',
  templateUrl: './card-button-back.component.html',
  styles: []
})
export class CardButtonBackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
