import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { parseDate } from 'ngx-bootstrap/chronos';
import { GrowlService } from './growl.service';

@Injectable()
export class AuthService {

  authEvent = new EventEmitter();
  currentDate=new Date();
  constructor(
    private router: Router,
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private growlService:GrowlService
  ) { }
  today: string ;

  loginUser(user, url: string, callback) {
    try {
      this.httpService.request({ method: 'post', url: url, bodyObject: user }).subscribe(
        res => {
          if (res.isOk) {
            var user = res.handled.data.userInfo;
            var permissions : any[] = [];
            user.roles.forEach(role => {
              if (role.permissions) {
                permissions.push(...role.permissions);
              }
            });
            user.permissions = permissions;

            localStorage.setItem('token', res.handled.data.token);
            localStorage.setItem('user', res.handled.data.userInfo.userId );
            localStorage.setItem('userInfo', JSON.stringify(user));
            localStorage.setItem('passwordExpiresDate',res.handled.data.userInfo.passwordExpiresDate)
            let startDate=localStorage.getItem('passwordExpiresDate');
            let endDate=this.currentDate.toISOString();

            let newDate=new Date(startDate).valueOf()-new Date(endDate).valueOf();

            callback({ status: 'success', response: res });

           // callback({ status: 'success', response: res });
          } else {
            callback({ status: 'error', response: res });
          }
        },
        err => {
          callback({ status: 'error', response: err });
        }
      );

    } catch (error) {
    }
  }

  logout() {
    this.localStorageService.clearLocalStorage();
    this.authEvent.emit(false);
    this.router.navigate(['/login']);
  }

  currentUser() {
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    return currentUser;
  }

  isAuthenticated(): Observable<boolean> {

    const subject = new Subject<boolean>();
    const parameters = {
      token: localStorage.getItem('token')
    };

    setTimeout(() => {
      if (localStorage.getItem('token')) {
        this.authEvent.emit(true);
        subject.next(true);
      } else {
        this.authEvent.emit(false);
        this.localStorageService.clearLocalStorage();
        subject.next(false);
      }
    });

    return subject.asObservable();
  }

}
