import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { LoadingService } from './services/loading.service';
import { AuthService } from './services/auth.service';

import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  isAuthenticated = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private ngSelectConfig: NgSelectConfig
  ) {
    this.ngSelectConfig.notFoundText = 'No record found';
    this.ngSelectConfig.loadingText = 'Loading...';
    this.ngSelectConfig.addTagText = 'Insert Tag';
    this.ngSelectConfig.clearAllText = 'Clear';
    this.ngSelectConfig.typeToSearchText = 'Search';

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.authService.authEvent.subscribe(
      data => {
        this.isAuthenticated = data;
      }, error => {
        this.isAuthenticated = false;
      }
    )

    this.loadingService.complete();// temp
  }
}
