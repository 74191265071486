import {
  Component,
  forwardRef,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  Host,
  Self,
  Optional,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  SimpleChanges,
  SimpleChange,
  Renderer2
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as sharedMethods from '../../../shared-methods';
import { CommonDataService } from '../../../services/common-data.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppDropDownComponent } from './drop-down.component';

@Component({
  selector: "app-labeled-drop-down",
  templateUrl: "./labeled-drop-down.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppLabeledDropDownComponent),
      multi: true
    }
  ]
})
export class AppLabeledDropDownComponent extends AppDropDownComponent {
  @Input() disabled = false;
  @Input() id: string;
  @Input() isRequired: boolean = false;
  @Input() multiple: boolean = false;
  @Input() label: string;
  @Input() placeholder: string = "";
  @Input() value: string ="";
  @Input() virtualScroll: boolean = false;
}
